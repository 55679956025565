<template>
  <div data-aos="fade-in" class="">

    <div class="relative overflow-hidden" :class="[widgetHeight]">
      <CPicture
                no-hover-zoom
                preload
                :lazy="lazy"
                :blend="blended"
                :zoom-direction="zoomDirection"
                :src="src"
                :alt="alt"
                :img-disclaimer="imgDisclaimer"
                :img-disclaimer-layout="imgDisclaimerLayout"
                :img-disclaimer-transform="imgDisclaimerTransform"
                :img-classname="imgClassname"
                :img-contrast="imgContrast"
                :img-height="imgHeight"
                :fit="fit"
                :crop="crop"
                :fp-x="fpX"
                :fp-y="fpY"
                :fp-z="fpZ"
                :height="height"
                :width="width"
                :modifiers="{ 'w': width, 'h': height }"
                :sizes="sizes"
                :vibrance="vibrance"
                :quality="quality" />
      <div class="z-50 absolute inset-0">
        <slot />
      </div>
    </div>

    <div v-if="logo" class="flex flex-col">
      <div class="border-none py-8 pl-8">
        <NuxtLink href="/" class="border-none">
          <img src="/images/logo.svg" class="logo" width="90" height="50" alt="Hauptseite">
        </NuxtLink>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
export interface Props {
  alt: string
  blend?: string
  blendMode?: 'custom' | 'readable' | 'readable lighter' | 'phased red' | 'fade to white'
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'clamp' | 'clip' | 'crop' | 'facearea' | 'fillMax' | 'max' | 'min' | 'scale'
  crop?: string,
  fpX?: number
  fpY?: number
  fpZ?: number
  headingLarge?: string
  headingSmall?: string
  height?: number
  iconCol?: string
  imgClassname?: string
  imgContrast?: number
  vibrance?: number
  imgDisclaimer?: string
  imgDisclaimerLayout?: 'default' | 'downRight' | 'lowerRight'
  imgDisclaimerTransform?: 'none' | 'normal'
  layout?: 'normal' | 'large' | 'larger' | 'small' | 'full'
  lazy?: boolean
  logo?: boolean
  quality?: number
  sizes?: string
  src: string
  titleLarge?: string
  titleSmall?: string
  width?: number
  zoomDirection?: 'in' | 'out' | 'none'
}
const props = withDefaults(defineProps<Props>(), {
  blend: undefined,
  fit: 'crop',
  fpX: undefined,
  fpY: undefined,
  fpZ: undefined,
  crop: 'focalpoint',
  blendMode: 'custom',
  headingLarge: 'div',
  headingSmall: 'h1',
  iconCol: undefined,
  imgClassname: undefined,
  imgContrast: 1.2,
  imgDisclaimer: undefined,
  imgDisclaimerLayout: undefined,
  imgDisclaimerTransform: undefined,
  imgHeight: 400,
  layout: 'normal',
  height: 260,
  quality: undefined,
  sizes: undefined,
  titleLarge: undefined,
  titleSmall: undefined,
  vibrance: 40,
  width: 400,
  zoomDirection: 'in',
})
const layoutMap = {
  'small': { widgetHeight: 'h-[260px] sm:h-[300px]', imgHeight: 300 },
  'normal': { widgetHeight: 'h-[460px] sm:h-[500px]', imgHeight: 400 },
  'large': { widgetHeight: 'h-[450px] sm:h-[600px]', imgHeight: 600 },
  'larger': { widgetHeight: 'h-[450px] sm:h-[1060px]', imgHeight: 1000 },
  'full': { widgetHeight: 'h-[100vh] max-h-[1200px]', imgHeight: 1000 },
}
const widgetHeight = layoutMap[props.layout].widgetHeight
const imgHeight = layoutMap[props.layout].imgHeight

const blendMap: Record<string, string> = {
  'custom': props.blend ?? '',
  'phased red': 'bg-[#22000022] bg-blend-multiply',
  'readable': 'bg-gradient-to-b from-[#0000003d] to-[#ffffff0a]',
  'readable lighter': 'bg-gradient-to-b from-[#0000002d] to-[#ff00000a]',
  'fade to white': 'bg-gradient-to-b from-[#0000004d] via-transparent to-[#ffffffff]',
}
const blended = blendMap[props.blendMode]
</script>
